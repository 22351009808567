import { useRouter } from 'next/router';
import { FormEvent, useState } from 'react';
import { isHot97 } from '../dictionary';

const NotFound = ({ }) => {
  const router = useRouter()

    const searchQuery = router.query.s

    const submitForm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        router.push({
            pathname: '/search',
            query: { s: query },
        })
    }

    const [query, setQuery] = useState('')
    const handleSearchParam = (setValue: (value: string) => void) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    return (
        <>
            <header className='pt-12 text-content px-24 xl:w-1/2 xlg:w-1/2 md:w-2/3 sm:w-4/5 text-center m-auto'>
                <h1 className='max-w-var(--responsive--aligndefault-width) mt-var(--global--spacing-vertical) md:mt-0 md:mt-110px text-uppercase transition-all duration-1000" font-[Judge]'>
                    Aw, snap! Something went wrong here. Maybe we can still help you.
                </h1>
            </header>
            <main id='main' className='md:pt-12 text-content p-24 m-auto xl:w-1/2 xlg:w-1/2 md:w-2/3 sm:w-4/5 text-center text-ls'>
                <div className='w-full'>
                    <section className='entry-content error-404-content font-[Judge]'>
                        <p>Try searching the site using relevant topics, tags, or keywords.</p>
                        <div className='py-8'>
                            <form role='search'
                                    className='block relative '
                                    method='get'
                                    action='/search'
                                    data-hs-cf-bound='true'
                                    onSubmit={submitForm}
                            >
                                <input
                                    type='search'
                                    className={`border rounded-full box-border font-[IBMPlex-mono] ${isHot97() ? 'bg-white text-black' : 'bg-black text-white'} border-none w-full text-base px-5 py-3 align-middle mr-0 ml-0 outline-none`}
                                    id='header-search'
                                    name='s'
                                    defaultValue={searchQuery}
                                    onChange={handleSearchParam(setQuery)}
                                />
                                <input type='submit' 
                                    className="bg-yellow-400 border rounded-full border-solid outline-none border-black font-[IBMPlex-mono] text-black uppercase text-base leading-[1.25rem] absolute top-1 right-1 tracking-tighter px-6 py-2 inline-block mt-0 transition-all duration-300 ease-in-out hover:bg-black hover:border-hot-yellow hover:text-hot-yellow"
                                    value='Search'/>
                            </form>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default NotFound
